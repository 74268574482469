import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import classes from '@/styles/login.module.css';
import ObscuredInput from '@/components/Reusable/Formik/ObscuredInput';
import PasswordRules from '../change-password/PasswordRules';

function NewPasswordForm({
  handleInputChange,
  formData,
  loading,
  handleNewPasswordSubmit,
}) {
  const [isValid, setIsValid] = useState(false);

  return (
    <form
      className={classes.form}
      onSubmit={(e) => {
        e.preventDefault();
        handleNewPasswordSubmit();
      }}
    >
      <h2 className={classes.title}>Please change your password</h2>
      <ObscuredInput
        variant='outlined'
        onChange={handleInputChange}
        placeholder='Enter your new password'
        label='New Password'
        name='newPassword'
        className={`${classes.input} ${classes.basicInput}`}
        InputLabelProps={{
          style: { color: '#040059' },
        }}
      />
      <ObscuredInput
        variant='outlined'
        onChange={handleInputChange}
        placeholder='Re-enter your new password'
        label='Repeat New Password'
        name='newRepeat'
        className={`${classes.input} ${classes.basicInput}`}
        InputLabelProps={{
          style: { color: '#040059' },
        }}
      />
      <LoadingButton
        loading={loading}
        color='secondary'
        variant='contained'
        type='submit'
        disabled={!isValid || loading}
      >
        Change Password
      </LoadingButton>

      <PasswordRules formData={formData} setIsValid={setIsValid} />
    </form>
  );
}

export default NewPasswordForm;
