import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

const TRUSTElOGOIMAGE =
  'https://privacy.truste.com/ctv/images/certified-seal/PSV/seal_PSV_en_m.svg';
const TRUSTElOGOURL =
  'https://privacy.truste.com/privacy-seal/validation?rid=6a775f8b-f5c3-4301-93a2-10a5c4a3f105';

function TrusteLogo() {
  return (
    <Link href={TRUSTElOGOURL} target='_blank'>
      <Image
        alt='Preview'
        src={TRUSTElOGOIMAGE}
        height={42}
        width={142}
        style={{ cursor: 'pointer' }}
      />
    </Link>
  );
}

export default TrusteLogo;
