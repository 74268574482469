import React from 'react';
import Head from 'next/head';
import { Typography } from '@mui/material';

export default function PageTitle(props) {
  const { title, headTitle, subtitle, variant } = props;

  let titleText = 'Cornerstone Hub';
  if (title) {
    titleText = title + ' | ' + titleText;
  } else if (headTitle) {
    titleText = headTitle + ' | ' + titleText;
  }

  return (
    <>
      <Head>
        <title>{titleText}</title>
      </Head>
      <Typography variant={variant || 'h1'}>{title}</Typography>
      {subtitle && <Typography variant='subtitle1'>{subtitle}</Typography>}
    </>
  );
}
